import actionTypes from './const';

const initialState = {
  youtubeEmbedUrl: '',
  youtubePlaylist: []
};

const widgetvideo = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_WIDGET_VIDEO_YOUTUBE:
      return {
        ...state,
        youtubeEmbedUrl: action.value
      };
    case actionTypes.SET_WIDGET_PLAYLIST:
      return {
        ...state,
        youtubePlaylist: action.value
      };
    default:
      return state;
  }
};

export default widgetvideo;
