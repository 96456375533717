import actionTypes from './const';
import API from '../../../api';

export const setWidgetVideoYoutube = value => ({
  type: actionTypes.SET_WIDGET_VIDEO_YOUTUBE,
  value
});

export const setWidgetPlaylist = value => ({
  type: actionTypes.SET_WIDGET_PLAYLIST,
  value
});

export const reduxGetPlayList = () => dispatch =>
  API.getYoutubePlaylist()
    .then(res => {
      if (res && res.data) {
        dispatch(setWidgetPlaylist(res.data));
      }
    })
    .catch(err => {
      throw err;
    });
