import { React, Component, PropTypes, _ } from 'libraries';
import {
  BaseFooter,
  BaseHeader,
  BaseSidebar,
  MainFeatured,
  BannerHTML
} from 'components';
import {
  setLoader,
  API,
  setBannerHeader,
  setBannerSidebar,
  setBannerFooter,
  setWidgetVideoYoutube,
  setSocialFacebook,
  setSocialTwitter,
  setSocialInstagram,
  setSocialYoutube,
  setSocialWhatsapp,
  setSocialWebsite,
  setSocialData,
  reduxGetMenuPustaka,
  reduxGetMenuHalaman,
  reduxGetMenuProfil,
  reduxGetPlayList
} from 'config';
import { connect } from 'react-redux';
import './styles.css';

class BaseLayout extends Component {
  static propTypes = {
    showSidebar: PropTypes.bool,
    children: PropTypes.any,
    match: PropTypes.any,
    loader: PropTypes.object,
    saveBanner: PropTypes.any,
    saveYoutubeUrl: PropTypes.func,
    saveSocial: PropTypes.object,
    banner: PropTypes.object,
    reqMenuPustaka: PropTypes.func,
    reqMenuHalaman: PropTypes.func,
    reqMenuProfil: PropTypes.func,
    reqGetPlayList: PropTypes.func,
    showHeaderBanner: PropTypes.bool
  };

  static defaultProps = {
    showSidebar: true,
    showHeaderBanner: true
  };

  constructor(props) {
    super(props);
    this.state = {
      toggleNav: false
    };
  }

  componentDidMount() {
    this.initSite();
  }

  initSite = async () => {
    const {
      reqMenuProfil,
      reqMenuHalaman,
      reqMenuPustaka,
      reqGetPlayList
    } = this.props;

    // TODO: activate menu profile when ready
    // await reqMenuProfil();
    await reqMenuPustaka();
    await reqMenuHalaman();
    await this.getBanner();
    await this.getSocialLink();
    await reqGetPlayList();
  };

  getBanner = () => {
    const { saveBanner } = this.props;
    API.getBanners()
      .then(res => {
        if (res.status && res.data) {
          const banners = res.data;
          banners.forEach(banner => {
            switch (banner.type) {
              case 'header':
                saveBanner.header(banner.content);
                break;
              case 'sidebar':
                saveBanner.sidebar(banner.content);
                break;
              case 'footer':
                saveBanner.footer(banner.content);
                break;
              default:
                break;
            }
          });
        }
      })
      .catch(err => {
        console.log('TCL: BaseLayout -> getBanner -> err', err);
      });
  };

  getWidgetVideo = () => {
    const { saveYoutubeUrl } = this.props;
    API.getWidgetVideo()
      .then(res => {
        if (res.status && res.data) {
          const widgetVideo = res.data;
          widgetVideo.forEach(video => {
            if (video.type === 'youtube_widget') {
              saveYoutubeUrl(video.url);
            }
          });
        }
      })
      .catch(err => {
        console.log('TCL: BaseLayout -> getWidgetVideo -> err', err);
      });
  };

  getSocialLink = () => {
    const { saveSocial } = this.props;
    API.getSocialLink()
      .then(res => {
        if (res.status && res.data) {
          const socialData = res.data;
          saveSocial.data(socialData);
          socialData.forEach(social => {
            switch (social.type) {
              case 'facebook':
                saveSocial.facebook(social.url);
                break;
              case 'twitter':
                saveSocial.twitter(social.url);
                break;
              case 'instagram':
                saveSocial.instagram(social.url);
                break;
              case 'youtube':
                saveSocial.youtube(social.url);
                break;
              case 'whatsapp':
                saveSocial.whatsapp(social.url);
                break;
              case 'website':
                saveSocial.website(social.url);
                break;
              default:
                break;
            }
          });
        }
      })
      .catch(err => {
        console.log('TCL: BaseLayout -> getSocialLink -> err', err);
      });
  };

  toggleNav = () => {
    this.setState(prev => ({
      toggleNav: !prev.toggleNav
    }));
  };

  render() {
    const { toggleNav } = this.state;
    const {
      children,
      showSidebar,
      match,
      loader,
      banner,
      showHeaderBanner
    } = this.props;
    return (
      <div
        id="wrapper"
        style={{ height: toggleNav ? '100vh' : 'auto' }}
        className={toggleNav ? 'active' : ''}
      >
        <BaseHeader onNavClick={this.toggleNav} />
        {match && match.path && match.path === '/' ? (
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              <MainFeatured />
            </div>
          </div>
        ) : null}
        {showHeaderBanner && (
          <div className="row">
            <div className="columns small-12 medium-12 large-12 banner-container">
              <BannerHTML htmlContent={banner.header} />
            </div>
          </div>
        )}

        <main id="content" className="row sbr row-flex">
          <div
            className={`${
              showSidebar ? 'columns small-12 medium-6 large-8' : 'columns'
            }`}
          >
            {children}
          </div>
          {showSidebar && <BaseSidebar {...this.props} />}
          <div className="columns small-12 banner-container">
            <BannerHTML htmlContent={banner.footer} />
          </div>
        </main>
        <BaseFooter />
      </div>
    );
  }
}

const reduxState = state => ({
  loader: state.loader,
  banner: state.banner
});

const reduxDispatch = dispatch => ({
  reqLoader: p => dispatch(setLoader(p)),
  saveYoutubeUrl: p => dispatch(setWidgetVideoYoutube(p)),
  saveBanner: {
    header: p => dispatch(setBannerHeader(p)),
    footer: p => dispatch(setBannerFooter(p)),
    sidebar: p => dispatch(setBannerSidebar(p))
  },
  saveSocial: {
    facebook: p => dispatch(setSocialFacebook(p)),
    twitter: p => dispatch(setSocialTwitter(p)),
    instagram: p => dispatch(setSocialInstagram(p)),
    youtube: p => dispatch(setSocialYoutube(p)),
    whatsapp: p => dispatch(setSocialWhatsapp(p)),
    website: p => dispatch(setSocialWebsite(p)),
    data: p => dispatch(setSocialData(p))
  },
  reqMenuPustaka: () => dispatch(reduxGetMenuPustaka()),
  reqMenuHalaman: () => dispatch(reduxGetMenuHalaman()),
  reqGetPlayList: () => dispatch(reduxGetPlayList()),
  reqMenuProfil: () => dispatch(reduxGetMenuProfil())
});

export default connect(reduxState, reduxDispatch)(BaseLayout);
