import { React, PropTypes, _ } from 'libraries';
import { config } from 'config/api/url';

const GaleryPostCard = ({ data }) => {
  const image = `${data.url}`;
  return (
    <article
      className="columns medium-6 large-4"
      style={{ display: 'inline-block', opacity: 1 }}
    >
      <div className="work-item">
        <a
          href={image}
          target="_blank"
          rel="noopener noreferrer"
          className="gallery popup-link"
        >
          <div className="item-overlay gallery">
            <img
              style={{ width: '100%', height: '65vh', objectFit: 'cover' }}
              src={image}
              alt="People"
            />
          </div>
          <h4 className="caption">{data.title}</h4>
        </a>
      </div>
    </article>
  );
};

GaleryPostCard.propTypes = {
  data: PropTypes.object
};

GaleryPostCard.defaultProps = {
  data: {
    title: 'SUKSESNYA MTQ TINGKAT PROVINSI PAPUA KE-26 DI MERAUKE',
    Thumb: 'https://webicdn.com/sdirmember/25/24291/produk/img-1490782600.jpg'
  }
};

export default GaleryPostCard;
