import ApiRequest from './config';
import baseUrl from './url';

const API = {};
API.getPosts = ApiRequest.get(baseUrl.post, false);
API.getFeaturePost = ApiRequest.get(baseUrl.featurePost, false);
API.getRelatedPost = ApiRequest.get(baseUrl.relatedPost, false);
API.getPopularPost = ApiRequest.get(baseUrl.popularPost, false);
API.getUpdatedPost = ApiRequest.get(baseUrl.updatedPost, false);
API.searchPost = ApiRequest.get(baseUrl.searchPost, false);

API.getBanners = ApiRequest.get(baseUrl.banner, false);
API.getWidgetVideo = ApiRequest.get(baseUrl.widgetVideo, false);
API.getSocialLink = ApiRequest.get(baseUrl.socialLink, false);

API.getDocuments = ApiRequest.get(baseUrl.document, false);
API.getDownload = ApiRequest.get(baseUrl.download, false);

API.getGalery = ApiRequest.get(baseUrl.gallery, false);

API.getHalaman = ApiRequest.get(baseUrl.halaman, false);

API.getMenuPustaka = ApiRequest.get(baseUrl.menuPustaka, false);
API.getMenuHalaman = ApiRequest.get(baseUrl.menuHalaman, false);
API.getMenuProfil = ApiRequest.get(baseUrl.menuProfil, false);

API.getYoutubePlaylist = ApiRequest.get(baseUrl.youtubePlaylist, false);
API.getVideoGallery = ApiRequest.get(baseUrl.videoGallery, false);

API.getUpdatedVideos = ApiRequest.get(baseUrl.updatedVideo, false);
API.getUpdatedInfos = ApiRequest.get(baseUrl.updatedInfo, false);

export default API;
