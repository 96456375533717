import { React, PropTypes, _, Link } from 'libraries';
import { Tools } from 'utils';

const CardPostFeature = ({
  data,
  url,
  showSnippet,
  smallTitle,
  style,
  className
}) => {
  const image = Tools.getImage(data);
  return (
    <div className={`m-card-post-feature__wrapper ${className} `} style={style}>
      <div className="m-card-post-feature__content">
        <a href={url} title={data.title}>
          <div
            className="m-card-post-feature__thumbnail"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        </a>
        <ul
          className="m-card-post-feature__category-wrapper"
          style={{ display: 'inline-block' }}
        >
          {!_.isEmpty(data.categories) && _.isArray(data.categories) ? (
            data.categories.map((tag, index) => (
              <li key={index}>
                {/* <Link */}
                <a
                  rel="category tag"
                  href={`/search?keyword=${tag.slug}`}
                  className="m-card-post-feature__category-item"
                  title={tag.name}
                >
                  {tag.name}
                </a>
                {/* </Link> */}
              </li>
            ))
          ) : (
            <li>
              <span
                rel="category tag"
                className="m-card-post-feature__category-item--uncategories"
                title="Uncategories"
              >
                Tanpa kategori
              </span>
            </li>
          )}
        </ul>
        <div className="card-body m-card-post-feature__body">
          <h2
            className={`m-card-post-feature__title m-card-post-feature__title${
              smallTitle ? '--small' : ''
            }`}
          >
            {/* <Link to={url}> */}
            <a href={url} title={data.title}>
              {`${data.title.substr(0, 70)}`}
            </a>
            {/* </Link> */}
          </h2>
          {showSnippet && (
            <p className="m-card-post-feature__snippet">{data.snippet}</p>
          )}
        </div>
      </div>
    </div>
  );
};

CardPostFeature.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
  showSnippet: PropTypes.bool,
  smallTitle: PropTypes.bool,
  style: PropTypes.any,
  className: PropTypes.string
};

CardPostFeature.defaultProps = {
  showSnippet: true,
  smallTitle: false,
  className: ''
};
export default CardPostFeature;
