import { React, Component, PropTypes } from 'libraries';
import {
  HeaderNav,
  SocialNav,
  SearchNav,
  HeaderBrand
} from 'components/molecules';
import { NavButtonClose, NavButtonOpen } from 'components/atoms';
import { connect } from 'react-redux';

class BaseHeader extends Component {
  static propTypes = {
    socialLink: PropTypes.array,
    onNavClick: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.nav = null;
    this.state = {
      staticNav: false
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  showNav = () => {
    this.props.toggleNavigation();
  };

  handleScroll = e => {
    const documentHTML = e.srcElement.documentElement;
    const navHTML = this.nav;

    if (navHTML) {
      if (documentHTML.scrollTop > navHTML.scrollHeight) {
        this.setState({
          staticNav: true
        });
      } else {
        this.setState({
          staticNav: false
        });
      }
    }
  };

  renderMobile = () => {
    const { onNavClick } = this.props;
    return (
      <React.Fragment>
        <NavButtonClose onClick={onNavClick} />
        <nav id="mobile-advanced" className="mobile-advanced">
          <HeaderNav />
          <SearchNav />
        </nav>
      </React.Fragment>
    );
  };

  render() {
    const { staticNav } = this.state;
    const { socialLink, onNavClick } = this.props;
    return (
      <>
        {this.renderMobile()}
        <header
          id="header"
          className={`header type-3 ${staticNav ? 'shrink-bottom-line' : ''}`}
        >
          <div className="header-top">
            <div className="row">
              <div className="large-12 columns">
                <SocialNav data={socialLink} />
              </div>
            </div>
          </div>
          <HeaderBrand />
          <div ref={e => (this.nav = e)} className="header-bottom">
            <div className="row">
              <div className="large-12 columns">
                <NavButtonOpen onClick={onNavClick} />
                <nav id="navigation" className="navigation top-bar" data-topbar>
                  <HeaderNav />
                  <SearchNav />
                </nav>
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

const reduxState = state => ({
  socialLink: state.social.data
});

export default connect(reduxState, null)(BaseHeader);
