import { React, Component, cx, PropTypes } from 'libraries';

class Button extends Component {
  render() {
    const {
      fill,
      small,
      middle,
      large,
      info,
      danger,
      success,
      notice,
      onClick,
      ...props
    } = this.props;

    const btnClasses = cx({
      'btn-fill': fill,
      small,
      middle,
      large,
      info,
      error: danger,
      sucsuccess: success,
      notice
    });

    return (
      <button
        type="button"
        onClick={onClick}
        className={`button ${btnClasses}`}
      >
        {props.children}
      </button>
    );
  }
}

Button.propTypes = {
  fill: PropTypes.bool,
  small: PropTypes.bool,
  middle: PropTypes.bool,
  large: PropTypes.bool,
  info: PropTypes.bool,
  error: PropTypes.bool,
  success: PropTypes.bool,
  notice: PropTypes.bool,
  danger: PropTypes.bool,
  onClick: PropTypes.func
};
export default Button;
