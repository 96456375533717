import { React, Component, PropTypes } from 'libraries';
import { Skeleton, Headline } from 'components';
import { BaseLayout } from 'layout';
import { API } from 'config';
import { config } from 'config/api/url';

class Halaman extends Component {
  static propTypes = {
    match: PropTypes.any,
    params: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEmpty: false,
      data: null
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(props) {
    const {
      match: { params }
    } = this.props;

    if (params !== props.match.params) {
      this.getData();
    }
  }

  getData = () => {
    const {
      match: { params }
    } = this.props;
    this.setState(
      {
        isLoading: true,
        isEmpty: false,
        data: null
      },
      () => {
        const payload = {
          path: params.halaman
        };
        API.getHalaman(payload)
          .then(res => {
            if (res.status) {
              document.title = res.data.title;
              this.setState({
                isLoading: false,
                data: res.data
              });
            }
          })
          .catch(err => {
            document.title = 'Halaman tidak ditemukan';
            console.log('TCL: Halaman -> getData -> err', err);
            this.setState({
              isLoading: false,
              isEmpty: true
            });
          });
      }
    );
  };

  render() {
    const { data, isLoading, isEmpty } = this.state;
    return (
      <BaseLayout showSidebar={false} showHeaderBanner={false} {...this.props}>
        <section id="main" className="post full-width">
          <div className="row">
            <div className="columns small-12 medium-12 large-12">
              {isLoading && (
                <React.Fragment>
                  <Skeleton
                    style={{
                      width: '100%',
                      paddingBottom: '30px',
                      marginBottom: 14
                    }}
                  />
                  <Skeleton
                    style={{
                      width: '80%',
                      paddingBottom: '30px',
                      marginBottom: 14
                    }}
                  />
                  <Skeleton
                    style={{
                      width: '40%',
                      paddingBottom: '30px',
                      marginBottom: 14
                    }}
                  />
                </React.Fragment>
              )}
              {data && (
                <React.Fragment>
                  <Headline data={data} showImage={false} />
                  {data.document && (
                    <embed
                      type="application/pdf"
                      src={`${process.env.REACT_APP_PORTAL_URL}/${data.document}`}
                      width="100%"
                      height="640"
                      alt="pdf"
                    />
                  )}
                  <div className="entry-content">
                    <div
                      dangerouslySetInnerHTML={{ __html: data.content }}
                    ></div>
                  </div>
                </React.Fragment>
              )}
              {isEmpty && (
                <div className="center">
                  <p>Halaman tidak ditemukan</p>
                </div>
              )}
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default Halaman;
