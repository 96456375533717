import { React, PropTypes, Component } from 'libraries';
import { CategoryList, WidgetVideo, BannerSidebar } from 'components/molecules';
import { connect } from 'react-redux';
import FeaturedEvents from 'components/molecules/FeaturedEvents';
import TabWidget from '../TabWidget';

class BaseSidebar extends Component {
  render() {
    const { youtube, match, youtubePlayList } = this.props;
    return (
      <div id="sidebar" className=" columns small-12 medium-4 large-4">
        <div className="sticky">
          <WidgetVideo source={youtube} />
          {youtubePlayList.map(list => {
            if (list.playlistId) {
              return (
                <WidgetVideo
                  key={list.playlistId}
                  source={`https://www.youtube.com/embed/videoseries?list=${list.playlistId}`}
                />
              );
            }
            return null;
          })}
          {/* <CategoryList /> */}
          <BannerSidebar />
          {match.path !== '/' && <FeaturedEvents />}
          <TabWidget />
        </div>
      </div>
    );
  }
}

BaseSidebar.propTypes = {
  youtubePlayList: PropTypes.array,
  youtube: PropTypes.string,
  match: PropTypes.object
};

BaseSidebar.defaultProps = {
  youtubePlayList: []
};

const reduxState = state => ({
  youtube: state.widgetvideo.youtubeEmbedUrl,
  youtubePlayList: state.widgetvideo.youtubePlaylist
});

export default connect(reduxState, null)(BaseSidebar);
