import { React, PropTypes } from 'libraries';

class VideoPlay extends React.Component {
  static propTypes = {
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      videoId: null
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const { params } = match;
    const { playid } = params;
    if (playid) {
      this.setState({
        videoId: playid
      });
    }
  }

  render() {
    const { videoId } = this.state;
    return (
      <div
        style={{
          backgroundImage: `url(https://i.ytimg.com/vi/${videoId}/hq720.jpg)`,
          backgroundSize: 'cover',
          backrgoundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: '#000'
        }}
      >
        {videoId && (
          <iframe
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0
            }}
            title="Video"
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        )}
      </div>
    );
  }
}

export default VideoPlay;
