import { React, PropTypes, Fragment, Link } from 'libraries';
import Website from 'website';
import { siteLogo } from 'assets/images';

const HeaderBrand = ({ title, description }) => (
  <div className="header-middle m-header-brand__wrapper">
    <div className="row">
      <div className="large-12 columns">
        <div className="header-middle-entry m-header-brand__content">
          <div className="m-header-brand__logo">
            <a href="/" title={title}>
              <img src={siteLogo} alt={title} title={title} />
            </a>
            <div className="m-header-brand__logo-holder">
              <h1 className="m-header-brand__title">
                <a href="/" title={title}>
                  {title}
                </a>
              </h1>
              <p className="m-header-brand__description">{description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

HeaderBrand.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

HeaderBrand.defaultProps = {
  title: Website.title,
  description: Website.description
};

export default HeaderBrand;
