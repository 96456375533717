import { React, PropTypes } from 'libraries';
import { connect } from 'react-redux';

const BannerSidebar = ({ htmlContent }) => (
  <div
    className="m-banner-sidebar"
    style={{ width: '100%', marginTop: 14, marginBottom: 14 }}
  >
    <div
      className="m-banner-sidebar__content"
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    ></div>
  </div>
);

BannerSidebar.propTypes = {
  htmlContent: PropTypes.string
};

BannerSidebar.defaultProps = {
  htmlContent: ''
};

const reduxState = state => ({
  htmlContent: state.banner.sidebar
});

export default connect(reduxState, null)(BannerSidebar);
