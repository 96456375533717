import { React, Component, PropTypes } from 'libraries';
import {
  RelatedArticles,
  AuthorBox,
  Headline,
  Skeleton,
  AddThis
} from 'components';
import { API } from 'config';
import { BaseLayout } from 'layout';
import { Helmet } from 'react-helmet';

class SinglePost extends Component {
  static propTypes = {
    match: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      post: null
    };
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true,
        isError: false
      },
      () => this.initPost()
    );
    this.initPost();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: { params }
    } = this.props;

    if (prevProps.match.params !== params) {
      this.initPost('update');
    }
  }

  initPost = async flag => {
    const {
      match: { params }
    } = this.props;

    if (flag && flag === 'update') {
      await this.setState({
        post: null,
        isLoading: true,
        isError: false
      });
    }

    const { type, slug } = params;
    if (type && slug) {
      const payload = {
        path: `url/${slug}`
      };
      API.getPosts(payload)
        .then(res => {
          window.scrollTo(0, 0);
          const post = res.data;
          document.title = post.title;
          this.setState({ post, isLoading: false, isError: false });
        })
        .catch(err => {
          this.setState({
            isLoading: false,
            isError: true
          });
          console.log('TCL: SinglePost -> initPost -> err', err);
        });
    }
  };

  postUrl = (type, url) => `${process.env.REACT_APP_PORTAL_URL}/${type}/${url}`;

  render() {
    const { post, isLoading, isError } = this.state;
    return (
      <BaseLayout {...this.props}>
        {post && (
          <Helmet>
            <meta property="og:title" content={post?.title} />
            <meta property="og:description" content={post?.snippet || ''} />
            <meta property="og:image" content={post?.thumbnail} />
            <meta
              property="og:url"
              content={this.postUrl(post?.type?.slug, post?.url)}
            />
            <meta name="twitter:card" content={post.thumbnail} />
            <meta property="og:site_name" content="LPTQ Papua" />
            <meta name="twitter:image:alt" content={post?.snippet || ''} />
          </Helmet>
        )}
        {/* <Breadcrumbs /> */}
        <div id="main">
          <div className="post full-width">
            {post && (
              <React.Fragment>
                <Headline data={post} showImage={post.thumbnail !== null} />
                {post.document && (
                  <embed
                    type="application/pdf"
                    src={`${process.env.REACT_APP_PORTAL_URL}/${post.document}`}
                    width="100%"
                    height="640"
                    alt="pdf"
                  />
                )}
                <div className="entry-content">
                  <div dangerouslySetInnerHTML={{ __html: post.content }}></div>
                </div>
              </React.Fragment>
            )}
            {isLoading && (
              <React.Fragment>
                <Skeleton
                  style={{
                    paddingBottom: '62.25%',
                    width: '100%',
                    marginBottom: 24
                  }}
                />

                <Skeleton
                  style={{
                    paddingBottom: 30,
                    width: '80%',
                    marginBottom: 24
                  }}
                />

                <Skeleton
                  style={{
                    paddingBottom: 20,
                    width: '40%',
                    marginBottom: 14
                  }}
                />
                <Skeleton
                  style={{
                    paddingBottom: 20,
                    width: '60%',
                    marginBottom: 14
                  }}
                />
                <Skeleton
                  style={{
                    paddingBottom: 20,
                    width: '30%',
                    marginBottom: 14
                  }}
                />
              </React.Fragment>
            )}
          </div>
          {/* {post && (
            <SocialShare postUrl={this.postUrl(post.type.slug, post.url)} />
          )} */}
          {/* <div className="single-nav clearfix">
            <a title="Previous post" href="####" className="prev">
              Previous article<b>Around the world on solar power</b>
            </a>
            <a title="Next post" href="####" className="prev">
              Next article<b>Introduce Kabayan Koding</b>
            </a>
          </div> */}

          <AuthorBox />
          {post && <RelatedArticles type={post.type.name} />}
        </div>
        <AddThis />
      </BaseLayout>
    );
  }
}

export default SinglePost;
