import actionTypes from './const';
import API from '../../../api';

const createMenuSearch = (arr = []) =>
  arr.map((menu, index) => ({
    id: `${menu.slug}-${menu.id}${index}`,
    title: menu.name,
    link: `/search?keyword=${menu.slug}`
  }));

const createMenuSearchByType = (arr = []) =>
  arr.map((menu, index) => ({
    id: `${menu.slug}-${menu.id}${index}`,
    title: menu.name,
    link: `/search?type=${menu.slug}`
  }));

const createMenuPage = (arr = []) =>
  arr.map((menu, index) => ({
    id: `${menu.url}-${menu.id}${index}`,
    title: menu.title,
    link: `/${menu.url}`
  }));

export const setMenuPustaka = value => ({
  type: actionTypes.SET_MENU_PUSTAKA,
  value
});

export const setMenuHalaman = value => ({
  type: actionTypes.SET_MENU_HALAMAN,
  value
});

export const setMenuProfil = value => ({
  type: actionTypes.SET_MENU_PROFIL,
  value
});

export const reduxGetMenuPustaka = () => dispatch => {
  API.getMenuPustaka()
    .then(res => {
      if (res && res.data) {
        const data = createMenuSearchByType(res.data);
        dispatch(setMenuPustaka(data));
      }
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export const reduxGetMenuHalaman = () => dispatch => {
  API.getMenuHalaman()
    .then(res => {
      if (res && res.data) {
        const data = createMenuPage(res.data);
        dispatch(setMenuHalaman(data));
      }
      return res;
    })
    .catch(err => {
      throw err;
    });
};

export const reduxGetMenuProfil = () => dispatch => {
  API.getMenuProfil()
    .then(res => {
      if (res && res.data) {
        const data = createMenuPage(res.data);
        dispatch(setMenuProfil(data));
      }
      return res;
    })
    .catch(err => {
      throw err;
    });
};
