import actionTypes from './const';

const initialState = {
  pustaka: [],
  halaman: [],
  profil: []
};

const menu = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MENU_PUSTAKA:
      return {
        ...state,
        pustaka: action.value
      };
    case actionTypes.SET_MENU_HALAMAN:
      return {
        ...state,
        halaman: action.value
      };
    case actionTypes.SET_MENU_PROFIL:
      return {
        ...state,
        profil: action.value
      };
    default:
      return state;
  }
};

export default menu;
