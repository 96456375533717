import React, { Component } from 'react';
import { API } from 'config';
import { CardPostFeature, Skeleton } from 'components/molecules';

class MainFeatured extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      posts: []
    };
  }

  componentDidMount() {
    this.setState(
      {
        isLoading: true
      },
      () => this._getPosts()
    );
  }

  _postUrl = (type, url) => `/${type}/${url}`;

  _getPosts = () => {
    API.getFeaturePost()
      .then(res => {
        this.setState({
          posts: res.data,
          isLoading: false,
          isError: false
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          isError: true
        });
      });
  };

  render() {
    const { posts, isError, isLoading } = this.state;

    return (
      <div className="o-main-feature__wrapper padding-top-2">
        <div className="row">
          {/* Large Columns */}
          <div className="columns small-12 medium-12 large-8">
            {isLoading && (
              <Skeleton style={{ width: '100%', paddingBottom: '62.25%' }} />
            )}
            {posts.map((data, i) => {
              if (i === 0) {
                return (
                  <CardPostFeature
                    url={this._postUrl(data.type.slug, data.url)}
                    data={data}
                    key={i}
                  />
                );
              }
              return null;
            })}
          </div>
          <div className="columns small-12 medium-12 large-4">
            {isLoading && (
              <React.Fragment>
                <Skeleton
                  style={{
                    width: '100%',
                    paddingBottom: '62.25%',
                    marginBottom: 20
                  }}
                />
                <Skeleton style={{ width: '100%', paddingBottom: '62.25%' }} />
              </React.Fragment>
            )}
            {posts.map((data, i) => {
              if (i !== 0) {
                return (
                  <CardPostFeature
                    smallTitle
                    showSnippet={false}
                    url={this._postUrl(data.type.name, data.url)}
                    key={i}
                    data={data}
                    className={i === 1 ? 'first-item' : ''}
                  />
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default MainFeatured;
