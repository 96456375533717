import { combineReducers } from 'redux';
import loader from './loader/reducer';
import banner from './banner/reducer';
import social from './social/reducer';
import widgetvideo from './widgetvideo/reducer';
import menu from './menu/reducer';

export const reducer = combineReducers({
  menu,
  loader,
  banner,
  social,
  widgetvideo
});

export * from './loader/action';
export * from './banner/action';
export * from './social/action';
export * from './widgetvideo/action';
export * from './menu/action';
