import { React, PropTypes, NavLink, _ } from 'libraries';
import { connect } from 'react-redux';
import Website from 'website';

const HeaderNav = ({ menu, menuPustaka, menuProfil, menuHalaman }) => {
  const mergeMenu = nav => {
    switch (nav.title) {
      case 'Pustaka':
        nav.submenu = [...menuPustaka];
        return nav;
      // case 'Profil':
      //   nav.submenu = [...menuProfil];
      //   return nav;
      case 'Info Lainnya':
        nav.submenu = [...menuHalaman];
        return nav;
      default:
        return nav;
    }
  };

  return (
    <div className="menu-primary-menu-container">
      <ul id="menu-primary-menu" className="menu">
        {menu && !_.isEmpty(menu) ? (
          menu.map((nav, i) => {
            mergeMenu(nav);
            return (
              <li key={i} className="menu-item">
                {/* <NavLink to={nav.link}> */}
                <a href={nav.link} title={nav.title}>
                  {nav.title}
                </a>
                {/* </NavLink> */}
                {nav.submenu && !_.isEmpty(nav.submenu) ? (
                  <ul className="sub-menu">
                    {nav.submenu.map((subnav, j) => (
                      <li key={j}>
                        {/* <NavLink exact to={subnav.link}> */}
                        <a href={subnav.link} title={subnav.title}>
                          {subnav.title}
                        </a>
                        {/* </NavLink> */}
                      </li>
                    ))}
                  </ul>
                ) : null}
              </li>
            );
          })
        ) : (
          <li className="menu-item">
            <NavLink activeStyle={{ fontWeight: 'bold' }} to="/">
              Home
            </NavLink>
          </li>
        )}
      </ul>
    </div>
  );
};

HeaderNav.propTypes = {
  menu: PropTypes.any,
  menuPustaka: PropTypes.array,
  menuProfil: PropTypes.array,
  menuHalaman: PropTypes.array
};

HeaderNav.defaultProps = {
  menuPustaka: [],
  menuProfil: [],
  menuHalaman: [],
  menu: Website.navigation.menu
};

const reduxState = state => ({
  menuPustaka: state.menu.pustaka,
  menuProfil: state.menu.profil,
  menuHalaman: state.menu.halaman
});

export default React.memo(connect(reduxState, null)(HeaderNav));
