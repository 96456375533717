import { React, PropTypes } from 'libraries';
import { Skeleton, Button, CardVideo } from 'components';
import { BaseLayout } from 'layout';
import { API } from 'config';

class VideoGallery extends React.Component {
  static propTypes = {
    location: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isError: false,
      videos: []
    };
  }

  componentDidMount() {
    this.initData();
  }

  initData = async () => {
    await this.setState({ isLoading: true, isError: false });
    await this.getVideos();
  };

  getVideos = async () =>
    API.getVideoGallery()
      .then(res => {
        this.setState({
          isLoading: false,
          isError: false,
          videos: res.data
        });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
          isError: true
        });
      });

  renderVideos = () => {
    const { videos, isLoading, isError, location } = this.state;

    if (isError) {
      return (
        <div className="colums small-12 medium-12 large-12">
          <div className="center">
            Terjadi kesalahan, muat ulang ?{' '}
            <Button onClick={this.initData}>muat ulang</Button>
          </div>
        </div>
      );
    }

    if (isLoading) {
      return [1, 2, 3, 4, 5, 6].map(val => (
        <div key={val} className="columns medium-6 large-4">
          <Skeleton
            style={{
              width: '100%',
              paddingBottom: '65.25%',
              marginBottom: 24
            }}
          />
        </div>
      ));
    }

    if (videos && videos.length === 0) {
      return (
        <div className="colums small-12 medium-12 large-12">
          <div className="center">Tidak ada video</div>
        </div>
      );
    }

    if (videos && videos.length > 0) {
      return videos.map(video => (
        <div
          key={video.id}
          className="columns medium-6 large-4"
          style={{ marginBottom: 24 }}
        >
          <CardVideo
            url={`https://www.youtube.com/watch?v=${video.youtubeId}`}
            date={video.created_at}
            title={video.name}
            imageUrl={`https://i.ytimg.com/vi/${video.youtubeId}/hq720.jpg`}
          />
        </div>
      ));
    }
  };

  render() {
    const { isLoading } = this.state;
    return (
      <BaseLayout showSidebar={false} showHeaderBanner={false} {...this.props}>
        <section id="main">
          <div className="section padding-off columns medium-12 large-12 background-color-off">
            <div className="row">
              <div className="relative widget widget_upcoming_events">
                <h2 className="section-title">Video</h2>
                <div className="row row-flex">{this.renderVideos()}</div>
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default VideoGallery;
