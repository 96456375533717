import { React, PropTypes, _, Link, moment } from 'libraries';
import { config } from 'config/api/url';
import { Tools } from 'utils';

const PostCard = ({
  data,
  url,
  showSnippet,
  showTitle,
  showThumbnail,
  showFooter,
  wrapperStyle
}) => {
  const image = Tools.getImage(data);
  return (
    <div style={wrapperStyle} className="post border m-post-card__wrapper">
      {showThumbnail && (
        <div className="entry-media m-post-card__thumbnail">
          {/* <Link className="image-post item-overlay" to={url}> */}
          <a href={url} title={data.title} className="image-post item-overlay">
            <img src={image} alt={data.title} title={data.title} />
          </a>
          {/* </Link> */}
        </div>
      )}

      <div className="entry-content m-post-card__entry-content">
        {showTitle && (
          <h3 className="entry-title m-post-card__entry-title">
            {/* <Link to={url} title={data.title}> */}
            <a href={url} title={data.title}>
              {data.title}
            </a>
            {/* </Link> */}
          </h3>
        )}
        {showSnippet && <p>{data.snippet}</p>}
      </div>

      {showFooter && (
        <footer className="entry-footer">
          <div className="left">
            {data.categories &&
              data.categories.map((category, i) => {
                if (i === 0) {
                  return (
                    <span key={i} className="cat-links">
                      <Link
                        to={`/search?keyword=${category.slug}`}
                        rel="category tag"
                        key={i}
                      >
                        <a
                          href={`/search?keyword=${category.slug}`}
                          title={category.name}
                        >
                          {category.name}
                        </a>
                      </Link>
                    </span>
                  );
                }
                return null;
              })}
          </div>

          <div className="right">
            <span className="posted-on">
              {/* <Link to="/"> */}
              <a href={url} title={data.title}>
                {data.createdAt &&
                  moment(data.createdAt).format('DD MMMM YYYY')}
              </a>
              {/* </Link> */}
            </span>
            {/* <Link className="post-like icon-eye" data-post_id={data.id} to="/"> */}
            <a href={url} className="post-like icon-eye" data-post_id={data.id}>
              <span className="vote-count">{data.view}</span>
            </a>
            {/* </Link> */}
          </div>
        </footer>
      )}
    </div>
  );
};

PostCard.propTypes = {
  data: PropTypes.object,
  url: PropTypes.string,
  showSnippet: PropTypes.bool,
  showTitle: PropTypes.bool,
  showThumbnail: PropTypes.bool,
  showFooter: PropTypes.bool,
  wrapperStyle: PropTypes.any
};

PostCard.defaultProps = {
  showSnippet: true,
  showTitle: true,
  showThumbnail: true,
  showFooter: true
};

export default PostCard;
