import { React, Component, PropTypes } from 'libraries';
import { PostCard, Skeleton } from 'components';
import { API } from 'config';
import _ from 'lodash';
import { BaseLayout } from 'layout';

class Home extends Component {
  static propTypes = {
    title: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      initLoading: false,
      // isError: false,
      isLoadMore: false,
      posts: [],
      page: 1,
      isLoaded: false
    };
  }

  componentDidMount() {
    const { title } = this.props;
    document.title = title;
    this.setState(
      {
        initLoading: true
      },
      () => {
        this.getPosts();
      }
    );
  }

  postUrl = (type, url) => `/${type}/${url}`;

  renderLoadmore = () => {
    const { isLoaded } = this.state;
    return (
      <div className="loadmore center">
        {!isLoaded ? (
          <button
            className="button"
            type="button"
            onClick={() => this.getPosts(1)}
          >
            Muat Lainnya
          </button>
        ) : (
          <p>Terima kasih sudah mengujungi situs kami</p>
        )}
      </div>
    );
  };

  getPosts = async (addPage = 0) => {
    const { page, posts } = this.state;
    try {
      if (addPage !== 0) {
        await this.setState({ isLoadMore: true });
      }

      const payload = {
        params: {
          publish: 1,
          page: page + addPage
        }
      };
      await API.getPosts(payload).then(res => {
        if (res && res.status) {
          if (_.isEmpty(res.data)) {
            return this.setState({
              initLoading: false,
              isLoadMore: false,
              isLoaded: true
            });
          }

          return this.setState({
            posts: [...posts, ...res.data],
            page: res.data.page,
            initLoading: false,
            isLoadMore: false
          });
        }
      });
    } catch (err) {
      console.log('err getPosts: ', err);
      if (err.error === 'NOT_FOUND') {
        this.setState({
          isLoaded: true,
          isLoadMore: false
        });
      }
    }
  };

  render() {
    const { posts, initLoading, isLoadMore } = this.state;
    return (
      <BaseLayout {...this.props}>
        <section id="main">
          <div className="section padding-off columns medium-12 large-12 background-color-off">
            <div className="row">
              <div className="relative">
                <h2 className="section-title">Portal Terbaru</h2>
                <div className="row post-list two-cols row-flex">
                  {!_.isEmpty(posts) && _.isArray(posts)
                    ? posts.map((data, i) => (
                        <div key={i} className="columns medium-6 large-6">
                          <PostCard
                            url={this.postUrl(data.type.slug, data.url)}
                            key={i}
                            data={data}
                          />
                        </div>
                      ))
                    : null}
                  {initLoading || isLoadMore
                    ? [1, 2, 3, 4].map(val => (
                        <div key={val} className="columns medium-6 large-6">
                          <Skeleton
                            style={{
                              width: '100%',
                              paddingBottom: '62.25%',
                              marginBottom: 14
                            }}
                          />
                          <Skeleton
                            style={{
                              width: '100%',
                              paddingBottom: '20px',
                              marginBottom: 24
                            }}
                          />
                          <Skeleton
                            style={{
                              width: '40%',
                              paddingBottom: '20px',
                              marginBottom: 24
                            }}
                          />
                        </div>
                      ))
                    : null}
                </div>
                {this.renderLoadmore()}
              </div>
            </div>
          </div>
        </section>
      </BaseLayout>
    );
  }
}

export default Home;
