/**
 * All about setting website
 */

const Website = {
  title: "Lembaga Pengembangan Tilawatil Qur'an",
  description: 'Kantor Provinsi Papua',
  navigation: {
    menu: [
      { title: 'Home', link: '/' },
      // {
      //   title: 'Profil',
      //   link: '#',
      //   submenu: []
      // },
      {
        title: 'Pustaka',
        link: '#',
        submenu: []
      },
      {
        title: 'Galeri',
        link: '#',
        submenu: [
          { title: 'Foto', link: '/foto' },
          { title: 'Video', link: '/video' }
        ]
      },
      { title: 'Berkas', link: '/berkas' },
      { title: 'Info Lainnya', link: '#', submenu: [] }
    ],
    footerMenu: []
  },
  about: "Lembaga Pengembangan Tilawatil Qur'an - Kantor provinsi papua.",
  address: 'Jl. Perkutut, Vim, Abepura, Kota Jayapura, Papua 99225',
  email: 'mtqpapua2020@gmail.com',
  phone: '081295624764 / 085243603399 / 082348209474',
  socialShare: [
    {
      title: 'Facebook',
      className: 'facebook',
      url: 'https://www.facebook.com/sharer/sharer.php?u='
    },
    {
      title: 'Twitter',
      className: 'twitter',
      url: 'http://twitter.com/'
    }
  ],
  categoryWidget: [
    { title: 'Berita', url: '/search?keyword=berita' },
    { title: 'Informasi', url: '/search?keyword=informasi' },
    { title: 'Lomba', url: '/search?keyword=lomba' },
    { title: 'MTQ', url: '/search?keyword=mtq' },
    { title: 'FAQ', url: '/faq' }
  ]
};

export default Website;
