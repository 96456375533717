import { React, PropTypes, moment } from 'libraries';

const CardVideo = ({ title, imageUrl, videoUrl, url, date, onClick }) => (
  <div className="m-card-video" style={{ margin: 0 }}>
    <div className="m-card-video__wrapper">
      {date && (
        <span className="m-card-video__date">
          {moment(date)
            .locale('id')
            .format('DD')}
          <b>
            {moment(date)
              .locale('id')
              .format('MMMM')}
          </b>
        </span>
      )}
      <div className="m-card-video__media">
        <a href={url} rel="noopener noreferrer" target="_blank" title={title}>
          <div
            style={{
              backgroundColor: '#ddd',
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              height: 0,
              paddingBottom: '66.66665%',
              width: '100%'
            }}
          />
        </a>
        <div className="m-card-video__content ">
          <h6 className="m-card-video__title">
            <a
              href={url}
              rel="noopener noreferrer"
              target="_blank"
              title={title}
            >
              {title}
            </a>
          </h6>
        </div>
      </div>
    </div>
  </div>
);

CardVideo.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  url: PropTypes.string,
  imageUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  onClick: PropTypes.func
};

export default CardVideo;
