import { React, PropTypes } from 'libraries';

const FooterBy = ({ text, title, link, version }) => (
  <div className="developed">
    {text}{' '}
    <a href={link} rel="noopener noreferrer" target="_blank">
      {title}
    </a>{' '}
    {version}
  </div>
);

FooterBy.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  version: PropTypes.string
};

FooterBy.defaultProps = {
  text: 'Develop by',
  title: 'Hudoro Solusi Digital',
  link: 'https://hsd.co.id',
  version: 'v2.0.2'
};

export default FooterBy;
