import { React, PropTypes, moment } from 'libraries';
import { config } from 'config/api/url';

const TableFile = ({ data, index }) => {
  let fileExt = data.file.split('.');
  fileExt = fileExt[fileExt.length - 1];
  return (
    <>
      <tbody>
        <tr>
          <td style={{ textAlign: 'center' }}>{index + 1}</td>
          <td>{data.name}</td>
          <td>{fileExt}</td>
          <td>{moment(data.createdAt).format('DD MMMM YYYY')}</td>
          <td>
            <a href={`${data.url}`} target="_blank" rel="noopener noreferrer">
              Download
            </a>
          </td>
        </tr>
      </tbody>
    </>
  );
};

TableFile.propTypes = {
  index: PropTypes.any,
  data: PropTypes.object
};
TableFile.defaultProps = {
  data: {
    name: 'Document LPTQ',
    type: 'text',
    create_at: '2019',
    file: 'download'
  }
};
export default TableFile;
