import { React, PropTypes } from 'libraries';

const Breadcrumbs = ({ data }) => (
  <div className="page-title" style={{ marginBottom: 8 }}>
    <div className="breadcrumbs">
      <span>
        <a href="/" title="Lembaga Pengembangan Tilawatil Qur'an">
          Home
        </a>
        <a href={`/search?keyword=${data.type.slug}`} title="">
          {data.type && data.type.name}
        </a>
        <strong>{data.title}</strong>
      </span>
    </div>
  </div>
);

Breadcrumbs.propTypes = {
  data: PropTypes.object
};

Breadcrumbs.defaultProps = {
  data: {
    Categories: 'uncategories',
    pageTitle:
      'Cek Kesiapan MTQ XVII, Tim Asistensi LPTQ Provinsi Papua Gelar Kunjungan Ke Nabire Congue iure curabitur incididunt consequat'
  }
};
export default Breadcrumbs;
