import { React, loadScript } from 'libraries';

class AddThis extends React.PureComponent {
  componentDidMount() {
    this.init();
  }

  init = () => {
    loadScript(
      '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5e11b2c59ef30410',
      function(err) {
        if (err) throw new Error('Addthis not loaded');
        console.log('Loaded');
      }
    );
  };

  render() {
    return <div id="addThis"></div>;
  }
}

export default AddThis;
