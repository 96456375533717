const baseUrl = {
  // POSTS
  post: `${process.env.REACT_APP_API_URL}/portal/posts`,
  searchPost: `${process.env.REACT_APP_API_URL}/portal/posts/search`,
  popularPost: `${process.env.REACT_APP_API_URL}/portal/posts/popular`,
  featurePost: `${process.env.REACT_APP_API_URL}/portal/posts/feature`,
  halaman: `${process.env.REACT_APP_API_URL}/portal/posts/page`,
  // SOCIAL MEDIA
  socialLink: `${process.env.REACT_APP_API_URL}/portal/social-media`,
  // YOUTUBE PLAYLIST
  youtubePlaylist: `${process.env.REACT_APP_API_URL}/portal/youtube-playlist`,
  // BANNER
  banner: `${process.env.REACT_APP_API_URL}/portal/banner`,
  // MENU
  menuPustaka: `${process.env.REACT_APP_API_URL}/portal/menu/pustaka`,
  menuHalaman: `${process.env.REACT_APP_API_URL}/portal/menu/halaman`,
  // GALLERY
  gallery: `${process.env.REACT_APP_API_URL}/portal/gallery/images`,
  videoGallery: `${process.env.REACT_APP_API_URL}/portal/gallery/videos`,
  document: `${process.env.REACT_APP_API_URL}/portal/gallery/documents`,
  // SEARCH
  // =======
  relatedPost: `${process.env.REACT_APP_API_URL}/portal/related_post`,
  updatedPost: `${process.env.REACT_APP_API_URL}/portal/updated_post`,
  widgetVideo: `${process.env.REACT_APP_API_URL}/portal/widget_video`,
  download: `${process.env.REACT_APP_API_URL}/portal/download`,
  menuProfil: `${process.env.REACT_APP_API_URL}/portal/menu_profil`,
  updatedVideo: `${process.env.REACT_APP_API_URL}/portal/updated_video`,
  updatedInfo: `${process.env.REACT_APP_API_URL}/portal/updated_info`
};

export default baseUrl;
