import {
  Home,
  SinglePost,
  About,
  Galery,
  Search,
  Document,
  Halaman,
  VideoPlay,
  VideoGallery
} from 'containers';

const routes = [
  {
    exact: true,
    path: '/',
    title: 'Beranda - Website Lptq Papua',
    component: Home
  },
  { exact: true, path: '/foto', title: 'Halaman Galeri', component: Galery },
  {
    exact: true,
    path: '/video',
    title: 'Halaman Galeri',
    component: VideoGallery
  },
  {
    exact: true,
    path: '/video/:playid',
    title: 'Putar Video',
    component: VideoPlay
  },
  { exact: true, path: '/search', component: Search },
  { exact: true, path: '/berkas', component: Document },
  { exact: true, path: '/:halaman', component: Halaman },
  {
    exact: true,
    path: '/:type/:slug',
    title: 'Postingan',
    component: SinglePost
  },
  { exact: true, path: '/tentang', component: About }
];

export default routes;
