import { React, PropTypes, moment, Link } from 'libraries';
import { Tools } from 'utils';

const EventItem = ({ data, onClick }) => (
  <li className="has-thumb">
    <div className="event-container">
      {data.created_at && (
        <span className="event-date">
          {moment(data.created_at)
            .locale('id')
            .format('DD')}
          <b>
            {moment(data.created_at)
              .locale('id')
              .format('MMMM')}
          </b>
        </span>
      )}
      <div className="event-media">
        <div className="item-overlay">
          <img src={Tools.getImage(data)} alt="" />
        </div>
        <div className="event-content with-excerpt">
          <h4 className="event-title">
            {/* <Link onClick={onClick} to={`/${data.type.slug}/${data.url}`}> */}
            <a
              onClick={onClick}
              href={`/${data.type.slug}/${data.url}`}
              title={data.title}
            >
              {data.title}
            </a>
            {/* </Link> */}
          </h4>
          <div className="event-text">{data.desc}</div>
        </div>
      </div>
    </div>
  </li>
);

EventItem.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func
};

export default EventItem;
